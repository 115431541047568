import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class FinanceReportService {
  constructor(private http: HttpClient) {}

  getMedicalUnitDetails(): Observable<any> {
    const results = this.http
      .get(
        `${environment.pythonUrl}/api/get_medical_unit?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            AuthenticationToken: localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  ledgerOfAccounts(value): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/voucher/new_ledger_of_account/?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&book_account=' +
          value.book_account +
          '&from_coa=' +
          value.from_coa +
          '&to_coa=' +
          value.to_coa +
          '&from_date=' +
          value.from_date +
          '&to_date=' +
          value.to_date,
        // return this.http.get(`${environment.pythonUrl}/finance/voucher/ledger_of_account/?medical_unit_id=` + localStorage.getItem('current_medical_unit_id') + '&book_account=' + value.book_account + '&account_group=' + value.account_group + '&chart_account=' + value.chart_account + '&fiscal_year=' + value.fiscal_year + '&from_fiscal_period=' + value.from_fiscal_period + '&to_fiscal_period=' + '&from_date=' + value.from_date + '&to_date=' + value.to_date
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  trialBalance(value): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/voucher/new_trail_balance/?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&book_account=' +
          value.book_account +
          '&from_date=' +
          value.from_date +
          '&to_date=' +
          value.to_date +
          '&from_coa=' +
          value.from_coa +
          '&to_coa=' +
          value.to_coa +
          '&is_zero=' +
          value.is_zero +
          '&as_of_date=' +
          value.as_of_date,
        // return this.http.get(`${environment.pythonUrl}/finance/voucher/trail_balance/?medical_unit_id=` + localStorage.getItem('current_medical_unit_id') + '&book_account=' + value.book_account + '&from_date=' + value.from_date + '&to_date=' + value.to_date + '&from_coa=' + value.from_coa + '&to_coa=' + value.to_coa
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  activityWiseTrialBalance(value, page, perPage): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/voucher/activity_wise_trial_balance/?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&book_account=' +
          value.book_account +
          '&fiscal_year=' +
          value.fiscal_year +
          '&from_fiscal_period=' +
          value.from_fiscal_period +
          '&to_fiscal_period=' +
          value.to_fiscal_period +
          '&from_date=' +
          value.from_date +
          '&to_date=' +
          value.to_date +
          '&page=' +
          page +
          '&per_page=' +
          perPage,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  activityWiseTrialBalanceReport(value): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/voucher/activity_wise_trial_balance_report/?auth_token=` +
          localStorage.getItem('auth_token') + '&medical_unit_id=' +
        localStorage.getItem('current_medical_unit_id') +
        '&book_of_account=' +
        value.book_of_account +
        '&fiscal_year=' +
        value.fiscal_year +
        '&from_date=' +
        value.from_date +
        '&to_date=' +
        value.to_date,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  bankReconciliation(value): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/voucher/bank_recon/?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&book_account=' +
          value.book_account +
          '&account_group=' +
          value.account_group +
          '&chart_account=' +
          value.chart_account +
          '&from_date=' +
          value.from_date +
          '&to_date=' +
          value.to_date,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  activityWiseTrialBalanceLevelWise(value): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/voucher/activity_wise_trial_balance_level_wise/?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&book_account=' +
          value.book_account +
          '&fiscal_year=' +
          value.fiscal_year +
          '&fiscal_period=' +
          value.fiscal_period +
          '&from_date=' +
          value.from_date +
          '&to_date=' +
          value.to_date +
          '&from_account_level=' +
          value.from_account_level +
          '&to_account_level=' +
          value.to_account_level,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  balanceSheet(value): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/voucher/balance_sheet/?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&book_account=' +
          value.book_account +
          '&from_date=' +
          value.from_date +
          '&to_date=' +
          value.to_date,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  newBalanceSheet(value): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/voucher/new_balance_sheet/?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&book_account=' +
          value.book_account +
          '&from_date=' +
          value.from_date +
          '&to_date=' +
          value.to_date +
          '&is_zero=' +
          value.is_zero +
          '&as_of_date=' +
          value.as_of_date,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  incomeStatement(value): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/voucher/income_statement/?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&book_account=' +
          value.book_account +
          '&from_date=' +
          value.from_date +
          '&to_date=' +
          value.to_date +
          '&is_zero=' +
          value.is_zero,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  newIncomeStatement(value): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/voucher/new_income_statement/?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&book_account=' +
          value.book_account +
          '&from_date=' +
          value.from_date +
          '&to_date=' +
          value.to_date +
          '&is_zero=' +
          value.is_zero +
          '&as_of_date=' +
          value.as_of_date,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
  unBalanceSheet(value): Observable<any> {
    return this.http
      .get(
        `${environment.pythonUrl}/finance/voucher/ub_balance/?medical_unit_id=` +
          localStorage.getItem('current_medical_unit_id') +
          '&book_account=' +
          value.book_account,
        {
          headers: new HttpHeaders({
            Authorization: localStorage.getItem('auth_token'),
            'Content-Type': 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
  }
}
