import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HrReportsService {
  constructor(private http: HttpClient) {}
  employeeProbationReport(
    current_page,
    per_page,
    start_date,
    end_date,
    reportType,
    departmentSearchValue,
    employeeFileId,
    statusSearchValue,
    probationStatusValue,
  ): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/employee_probation_contract_report?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          current_page +
          '&per_page=' +
          per_page +
          '&from_date=' +
          start_date +
          '&to_date=' +
          end_date +
          '&type=' +
          reportType +
          '&depart=' +
          departmentSearchValue +
          '&file_no=' +
          employeeFileId +
          '&status=' +
          statusSearchValue +
          '&probation_status=' +
          probationStatusValue,
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  employeeLicenseReport(
    start_date,
    end_date,
    licenseType,
    departmentSearchValue,
    employeeFileId,
    statusSearchValue,
    current_page,
    per_page,
    licenseStatus,
  ): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/employee_license_expiry_report?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&from_date=' +
          start_date +
          '&to_date=' +
          end_date +
          '&licance_type=' +
          licenseType +
          '&depart=' +
          departmentSearchValue +
          '&file_no=' +
          employeeFileId +
          '&status=' +
          statusSearchValue +
          '&page=' +
          current_page +
          '&per_page=' +
          per_page +
          '&license_status=' +
          licenseStatus,
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  employeeListReport(
    current_page,
    per_page,
    department,
    designation,
    salaryModel,
    employeeCategory,
    employee,
    religion,
  ): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/hr_employee_report?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          current_page +
          '&per_page=' +
          per_page +
          '&department=' +
          department +
          '&designation=' +
          designation +
          '&salary_model=' +
          salaryModel +
          '&employee_category=' +
          employeeCategory +
          '&employee=' +
          employee +
          '&religion=' +
          religion,
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  employeeListReportV2(
    current_page,
    per_page,
    startDate,
    endDate,
    department,
    designation,
    type,
    reason,
  ): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/employee_joining_and_leaving_report?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          current_page +
          '&per_page=' +
          per_page +
          '&start_date=' +
          startDate +
          '&end_date=' +
          endDate +
          '&department=' +
          department +
          '&designation=' +
          designation +
          '&type=' +
          type +
          '&reason_leaving=' +
          reason,
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  payrollListReport(
    current_page,
    per_page,
    month,
    department,
    designation,
  ): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/pay_monthly_report?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          current_page +
          '&per_page=' +
          per_page +
          '&department=' +
          department +
          '&designation=' +
          designation +
          '&month=' +
          month,
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  payrollListReportV2(
    current_page,
    per_page,
    fromMonth,
    toMonth,
    department,
    designation,
    allowance,
    deduction,
  ): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/allowance_and_deduction_report?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&allowance=' +
          allowance +
          '&deduction=' +
          deduction +
          '&page=' +
          current_page +
          '&per_page=' +
          per_page +
          '&department=' +
          department +
          '&designation=' +
          designation +
          '&from_month=' +
          fromMonth +
          '&to_month=' +
          toMonth,
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  categoryWisePayrollReport(month, department, category): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/pay_category_wise_report?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&department=' +
          department +
          '&employee_category=' +
          category +
          '&month=' +
          month,
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }

  categoryWisePayrollReportV2(month, department, category): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/pay_department_wise_report?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&department=' +
          department +
          '&employee_category=' +
          category +
          '&month=' +
          month,
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  monthWiseDepartmentPayroll(month, department): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/hr/department_wise_payroll_summary?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&department=' +
          department +
          '&month=' +
          month,
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
