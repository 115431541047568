import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InventoryVendorService {
  constructor(private http: HttpClient) {}

  addVendor(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/vendor_list?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getVendorDetail(id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/vendor_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getVendorListing(current_page, per_page, vendorName): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/vendor_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&page=' +
          current_page +
          '&per_page=' +
          per_page +
          '&name=' +
          vendorName,
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getActiveVendorListing(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/active_vendor_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateVendor(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/inventory/vendor_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  addVendorContact(value): Observable<any> {
    const results = this.http
      .post(
        `${environment.pythonUrl}/inventory/vendor_contact_person?auth_token=` +
          localStorage.getItem('auth_token'),
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  updateVendorContact(value, id): Observable<any> {
    const results = this.http
      .put(
        `${environment.pythonUrl}/inventory/vendor_contact_person_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          medical_unit_id: localStorage.getItem('current_medical_unit_id'),
          value,
        },
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getVendorContactDetail(id: any): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/vendor_contact_person_detail?auth_token=` +
          localStorage.getItem('auth_token') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getVendorContactListing(id): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/vendor_contact_person?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id') +
          '&id=' +
          id,
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
  getVendorListingWithOutPaignation(): Observable<any> {
    let results = this.http
      .get(
        `${environment.pythonUrl}/inventory/vendor_list?auth_token=` +
          localStorage.getItem('auth_token') +
          '&medical_unit_id=' +
          localStorage.getItem('current_medical_unit_id'),
        {
          headers: new HttpHeaders({
            // //'AuthenticationToken': localStorage.getItem('auth_token'),
            Accept: 'application/json',
          }),
        },
      )
      .pipe(map((data) => data));
    return results;
  }
}
